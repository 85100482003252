"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RankingWidgetTable = void 0;
var React = __importStar(require("react"));
var _ = __importStar(require("lodash"));
var deltaArrowNumber_1 = require("./deltaArrowNumber");
var useQueryParamSynched_1 = require("../hooks/useQueryParamSynched");
var rankingUtil_1 = require("./rankingUtil");
var filterList_1 = require("../lib/filterList");
var QPSortValues;
(function (QPSortValues) {
    QPSortValues["RANK"] = "rank";
    QPSortValues["DELTA"] = "delta";
    QPSortValues["NAME"] = "name";
})(QPSortValues || (QPSortValues = {}));
var svCollator = new Intl.Collator('sv');
function compareFn(sort, dir, year) {
    switch (sort) {
        case QPSortValues.RANK:
            return function (entry, other) { return (entry.ranks[year] - other.ranks[year]) * dir; };
        case QPSortValues.DELTA:
            return function (entry, other) { return (rankingUtil_1.getDelta(entry.ranks, year) - rankingUtil_1.getDelta(other.ranks, year)) * dir; };
        case QPSortValues.NAME:
            return function (entry, other) { return svCollator.compare(entry.name, other.name) * dir; };
        default:
            throw "Unknown sort '" + sort + "'";
    }
}
exports.RankingWidgetTable = function (props) {
    var entries = props.entries, years = props.years, setMunicipality = props.setMunicipality;
    var year = _.max(years);
    if (!year) {
        throw Error("Need a year to show rankings for.");
    }
    var _a = filterList_1.useFilterList([]), filterList = _a[0], onChange = _a[2];
    var renderRows = function (entries, year) {
        return entries.filter(function (e) { return filterList_1.namePassesFilter(e.name, filterList); }).map(function (e) {
            var name = e.name, ranks = e.ranks;
            var rank = ranks[year];
            var specialColor = function (rank) {
                switch (rank) {
                    case 1: return "gold";
                    case 2: return "silver";
                    case 3: return "bronze";
                    default: return "";
                }
            };
            return React.createElement("tr", { key: name, className: "muni-rows " + specialColor(rank), onClick: function () { return setMunicipality(name); } },
                React.createElement("td", { className: "year" }, rank),
                React.createElement("td", { className: "delta" },
                    React.createElement(deltaArrowNumber_1.DeltaArrowNumber, { ranks: ranks, latestYear: props.sortYear })),
                React.createElement("td", { className: "name" },
                    React.createElement("div", null,
                        name,
                        React.createElement("i", { className: "fas fa-chevron-right", "aria-hidden": "true" }))));
        });
    };
    var defaultSortDir = function (s) { return s !== QPSortValues.DELTA ? 1 : -1; };
    var _b = useQueryParamSynched_1.useQueryParamSynched("sort", QPSortValues.RANK), sort = _b[0], setSort = _b[1];
    var _c = useQueryParamSynched_1.useQueryParamSynched("sortDir", defaultSortDir(sort)), sortDir = _c[0], setSortDir = _c[1];
    entries.sort(compareFn(sort, sortDir, props.sortYear));
    var createOnClickHandler = function (s) {
        return function (e) {
            e.preventDefault();
            if (sort === s) {
                setSortDir(sortDir * -1);
            }
            else {
                setSortDir(defaultSortDir(s));
                setSort(s);
            }
        };
    };
    var arrowDir = function (s) {
        if (sort === s) {
            return sortDir < 0 ? "up" : "down";
        }
        return "down";
    };
    return React.createElement("table", { className: "ranking-table" },
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement("th", { className: "year " + (sort === QPSortValues.RANK ? "sort" : "") },
                    React.createElement("a", { onClick: createOnClickHandler(QPSortValues.RANK), href: "/start/ranking?sort=" + QPSortValues.RANK + "&sortDir=" + sortDir * -1 },
                        "Rank",
                        React.createElement("i", { className: "fas fa-chevron-" + arrowDir(QPSortValues.RANK), "aria-hidden": "true" }))),
                React.createElement("th", { className: "delta " + (sort === QPSortValues.DELTA ? "sort" : "") },
                    React.createElement("a", { onClick: createOnClickHandler(QPSortValues.DELTA), href: "/start/ranking?sort=" + QPSortValues.DELTA + "&sortDir=" + sortDir * -1 },
                        React.createElement("li", { className: "fa fa-arrow-up" }),
                        "/",
                        React.createElement("li", { className: "fa fa-arrow-down" }),
                        React.createElement("i", { className: "fas fa-chevron-" + arrowDir(QPSortValues.DELTA), "aria-hidden": "true" }))),
                React.createElement("th", { className: "name " + (sort === QPSortValues.NAME ? "sort" : "") },
                    React.createElement("form", null,
                        React.createElement("div", { className: "field" },
                            React.createElement("input", { autoComplete: "off", id: "table-scroller", name: "name", placeholder: "S\u00F6k kommun", type: "text", onChange: onChange })),
                        React.createElement("a", { onClick: createOnClickHandler(QPSortValues.NAME), href: "/start/ranking?sort=" + QPSortValues.NAME + "&sortDir=" + sortDir * -1 },
                            React.createElement("i", { className: "fas fa-chevron-" + arrowDir(QPSortValues.NAME), "aria-hidden": "true" })))))),
        React.createElement("tbody", { id: "muni-table" }, renderRows(entries, year)));
};
