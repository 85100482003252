"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Factors = exports.ChartDataTransformerRules = exports.gradingScaleTypeDescription = void 0;
var chartData_1 = require("../react/models/external/chartData");
// generate factors.yml: `bundle exec rails assets:generate_yaml`
// xscales for 'Konkurrens...' and 'Brottslighet/otrygghet'.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var xScalesGradingGoodnessLevelOrderHighLow = [
    'I mycket hög utsträckning',
    'I ganska hög utsträckning',
    'I varken hög eller låg utsträckning',
    'I ganska låg utsträckning',
    'I mycket låg utsträckning',
    'Inte alls',
    'Vet ej'
];
var xScalesGradingGoodnessLevelOrderLowHigh = [
    'Inte alls',
    'I mycket låg utsträckning',
    'I ganska låg utsträckning',
    'I varken hög eller låg utsträckning',
    'I ganska hög utsträckning',
    'I mycket hög utsträckning',
    'Vet ej'
];
var SURVEY_YEAR_LATEST = 2024;
var defaultFactorChartingArray = [
    {
        label: 'Företagare',
        chartDataTransformerRule: 'EntrepreneurRespondents',
        chart: {
            xscales: ['']
        }
    },
    {
        label: 'Företagare och politiker',
        chartDataTransformerRule: 'BothRespondents',
        chart: {
            xscales: ['']
        }
    }
];
var dashed2019FactorChartingArray = defaultFactorChartingArray
    .map(function (charting) { return (__assign(__assign({}, charting), { chart: __assign(__assign({}, charting.chart), { dashYears: [2019] }) })); });
var _Factors /*: Factor[] -- use this as a const context to infer types from instead*/ = [
    {
        slug: 'young_entrepreneurs',
        api_name: 'Ung Företagsamhet',
        ranking: false,
        source: 'statistics',
        description: 'Andelen elever per årskull som drev UF-företag.',
        long_description: 'Andelen elever per årskull som drev UF-företag.',
        name: 'Ung Företagsamhet',
        chartingArray: [
            {
                chart: {
                    yearLabelTransformerName: 'UF'
                }
            }
        ]
    },
    {
        slug: 'new_entrepreneurship',
        api_name: 'Nyföretagsamhet',
        ranking: true,
        ranking_years: [
            [
                2011,
                2019
            ]
        ],
        source: 'statistics',
        description: 'Antal nyföretagsamma per 1 000 invånare i kommunen som under det senaste året tillkommit gruppen företagsamma.',
        long_description: 'De individer som innehar F-skattsedel, är delägare i ett aktivt handelsbolag, är vd eller styrelseordförande i ett aktivt aktiebolag räknas som ”företagsamma”. De som senaste året blivit ”företagsamma” bedöms som ”nyföretagsamma”. Faktorn beskriver nyföretagsamma personer per 1 000 invånare i kommunen.',
        name: 'Nyföretagsamhet',
        chartingArray: [
            {
                chart: {
                    xscales: [
                        'Antal nyföretagsamma individer'
                    ]
                }
            }
        ]
    },
    {
        slug: 'employment_level',
        api_name: 'Andel i arbete',
        ranking: true,
        ranking_years: [
            [
                2000,
                2019
            ]
        ],
        source: 'statistics',
        description: 'Andelen invånare som förvärvsarbetar.',
        long_description: 'Faktorn visar andelen förvärvsarbetande personer i åldern 16-64 år av samtliga invånare i en kommun. Har kommunen ett bra företagsklimat är det lättare att starta och driva företag. Det skapar fler företag och i sin tur fler arbetstillfällen.',
        name: 'Andel i arbete'
    },
    {
        slug: 'employment_level_daytime_population',
        api_name: 'Jobb i kommunen',
        ranking: true,
        ranking_years: [
            [
                2020
            ]
        ],
        source: 'statistics',
        description: 'Jobb i kommunen i förhållande till befolkning',
        long_description: 'Relationen mellan antalet personer (20–74 år) som har sin arbetsplats i kommunen och antalet personer (20–74 år) som bor i kommunen. Har kommunen ett bra företagsklimat är det lättare att starta och driva företag. Det skapar fler företag och i sin tur fler arbetstillfällen.',
        extra_description: 'Ny rankinggrundad statistik från 2020. Värdet för Sverige är ett medel av alla kommuner där varje enskild kommun har samma vikt oberoende av befolkningsmängd.',
        name: 'Jobb i kommunen',
        chartingArray: [
            {
                factorComparison: {
                    lacksCountyData: true
                }
            }
        ]
    },
    {
        slug: 'municipal_tax',
        api_name: 'Kommunalskatt',
        ranking: true,
        ranking_years: [
            [
                2000
            ]
        ],
        source: 'statistics',
        description: 'Hur hög är den kommunala inkomstskatten?',
        long_description: 'Den totala kommunalskatten, inklusive skatt till regionen. Kommunalskatten har störst påverkan på människors privatekonomi. Den påverkar möjligheten att bygga det kapital som krävs för att starta företag. För företagen är det också viktigt att den lokala köpkraften är så stark som möjligt. En låg kommunalskatt gör också kommunen mer attraktiv för inflyttare, vilket är viktigt för företagens kompetensförsörjning.',
        name: 'Kommunalskatt'
    },
    {
        slug: 'municipal_equalization',
        api_name: 'Kommunal utjämning',
        ranking: false,
        source: 'statistics',
        description: 'Kommunalekonomiskt utjämningsbidrag utfall kr/invånare, källa SCB.',
        long_description: 'Kommunalekonomiskt utjämningsbidrag utfall kr/invånare, källa SCB.',
        name: 'Kommunal utjämning',
        chartingArray: [
            {
                chart: {
                    isIntegerData: true
                },
                factorComparison: {
                    singleDimensionComparable: false,
                    lacksCountyData: true
                }
            }
        ]
    },
    {
        slug: 'private_sector_employment',
        api_name: 'Marknadsförsörjning',
        ranking: true,
        ranking_years: [
            [
                2000
            ]
        ],
        source: 'statistics',
        description: 'Andel av hushållens inkomster som kommer från marknadsaktiviteter, t.ex. lön från privata företag.',
        long_description: 'Faktorn marknadsförsörjning visar hur stor andel av kommuninvånarnas inkomster som kommer från privata företag, föreningar och stiftelser. Marknadsförsörjning kan ses som ett mått på det lokala näringslivets vitalitet och omfattning. Att en kommun har en låg marknadsförsörjning beror på att en stor andel av invånarna får sina inkomster från det offentliga. Det kan leda till att förutsättningarna för entreprenörskap och nyföretagande försämras. Om marknadsförsörjningen ökar, och fler får sina inkomster från den privata sektorn, stärks finansieringen av den offentliga servicen.',
        name: 'Marknadsförsörjning'
    },
    {
        slug: 'amount_of_business',
        api_name: 'Arbetsställen',
        ranking: true,
        ranking_years: [
            [
                2000,
                2019
            ]
        ],
        source: 'statistics',
        description: 'Antalet arbetsställen per 1 000 invånare.',
        long_description: 'Antalet arbetsställen per 1 000 invånare. Svenskt Näringsliv ranking av företagsklimatet i Sveriges kommuner bygger på antal arbetsställen med minst en anställd. Källa: SCB:s Företagsregister.',
        extra_description: 'År 2019 genomförde SCB en statistikomläggning som innebar att RAMS-statistiken bytte metod och datakälla. Detta påverkar tidsserien för arbetsställen från och med 2020 års datapunkt. De största skillnaderna ser vi i arbetsställen med 0 anställda som ökat med ca 55 000 och arbetsställen med 1-9 anställda som minskat med ca 40 000. Detta indikerar en systematisk omklassificering av företag mellan dessa två storleksklasser.',
        name: 'Arbetsställen'
    },
    {
        slug: 'amount_of_business_absolute',
        api_name: 'Totalt antal företag',
        ranking: false,
        source: 'statistics',
        description: 'Totalt antal aktiva arbetsställen.',
        long_description: 'Totalt antal aktiva arbetsställen. Källa: SCB:s Företagsregister',
        name: 'Totalt antal företag',
        chartingArray: [
            {
                factorComparison: {
                    singleDimensionComparable: false,
                    includeSwedenByDefault: false
                }
            }
        ]
    },
    {
        slug: 'contracting',
        api_name: 'Entreprenader',
        ranking: true,
        ranking_years: [
            [
                2000
            ]
        ],
        source: 'statistics',
        description: 'Hur stor del av kommunens totala verksamhet som är utlagd på entreprenad i privat drift.',
        long_description: 'Faktorn entreprenader visar andelen av den kommunala verksamheten som köps in från privata företag, föreningar och stiftelser. Många företag är beroende av att kommuner är affärsmässiga och gör affärer med näringslivet genom att upphandla varor och tjänster och att de tillämpar valfrihetssystem. En kommun som lägger ut en stor andel av sin verksamhet på entreprenad bidrar till att marknaden och affärsmöjligheterna växer för företagen, vilket stimulerar näringslivet i kommunen.',
        name: 'Entreprenader'
    },
    {
        slug: 'services_to_businesses',
        api_name: 'Kommunens service och bemötande',
        ranking: true,
        ranking_years: [
            [
                2000
            ]
        ],
        source: 'survey',
        subtype: 'service',
        description: 'Kommunens service och bemötande',
        long_description: 'Den kommunala servicen har stor betydelse för företagens etablering och expansion. För ett enskilt företag kan det vara förödande om ett bygglovsärende eller ett serveringstillstånd drar ut på tiden. En bra kommunal service präglas av effektivitet, tillgänglighet och kunskap om företagandets villkor.',
        extra_description: 'Frågans formulering är ändrad 2020. Formuleringen 2002–2019 var ”Kommunens service till företagen”.',
        name: 'Kommunens service och bemötande vid handläggning av företagsärenden',
        chartingArray: dashed2019FactorChartingArray
    },
    {
        slug: 'application_of_laws',
        api_name: 'Kommunens tillämpning av lagar och regler',
        ranking: true,
        ranking_years: [
            [
                2000,
                2019
            ]
        ],
        source: 'survey',
        subtype: 'service',
        description: 'Kommunens tillämpning av lagar och regler.',
        long_description: 'Faktorn beskriver hur företagarna ser på kommunens tillämpning av lagar och regler. Tillämpningen handlar om effektivitet i ärendehanteringen, förståelse för företagets ärende och återkoppling om vilka möjligheter som finns. Innan 2007 kallades enkätfrågan "Kommunala regler och byråkratier".',
        extra_description: 'Denna fråga utgår ur enkäten från och med 2020.',
        name: 'Tillämpning av lagar och regler',
        chartingArray: defaultFactorChartingArray
    },
    {
        slug: 'competition_with_private_companies',
        api_name: 'I vilken utsträckning anser du att kommunens verksamheter tränger undan privat näringsverksamhet?',
        ranking: true,
        ranking_years: [
            [
                2000
            ]
        ],
        source: 'survey',
        subtype: 'service',
        description: 'I vilken utsträckning anser du att kommunens verksamheter tränger undan privat näringsverksamhet?',
        long_description: 'Faktorn mäter i vilken utsträckning företagen anser att kommunens verksamhet tränger undan privat verksamhet. En kommun bör fråga sig om det finns kommunalt utförda tjänster som lika väl hade kunnat utföras av företagen. Genom att lägga ut verksamheter på entreprenad, införa valfrihetssystem och utmaningsrätt kan kommunen uppmuntra till att offentligt finansierade verksamheter utförs av privata företag.',
        extra_description: 'Faktorns tidsserie bröts 2020 på grund av ny formulering av svarsalternativ. Tidigare svarsalternativ på skalan 1 till 6 där 1 = I stor utsträckning och 6 = Inte alls',
        name: 'Konkurrens från kommunen',
        chartingArray: dashed2019FactorChartingArray
            .map(function (charting) {
            return (__assign(__assign({}, charting), { factorComparison: {
                    singleDimensionComparable: true,
                    gradingScaleType: 'named-rev',
                    applicableToPoliticians: false
                } }));
        })
    },
    {
        slug: 'access_to_competent_labour',
        api_name: 'Hur är tillgången på arbetskraft med relevant kompetens',
        ranking: true,
        ranking_years: [
            [
                2000
            ]
        ],
        source: 'survey',
        subtype: 'service',
        description: 'Hur är tillgången på arbetskraft med relevant kompetens.',
        long_description: 'Tillgången på medarbetare med relevant kompetens är avgörande för ­företagens möjlighet att växa. Enligt Svenskt Näringslivs ­rekryteringsenkät ­misslyckas många rekryteringsförsök. Den enskilt största orsaken till ­företagens svårig­heter att rekrytera är brist på personer med rätt yrkes­erfarenhet, attityd och engagemang. Här kan kommunen bidra till att stärka kvaliteten i yrkesutbildningarna och på så sätt öka deras attraktivitet.',
        name: 'Tillgång till kompetens',
        chartingArray: defaultFactorChartingArray
    },
    {
        slug: 'roads_railways_airports',
        api_name: 'Vägnät, tåg- och flygförbindelser',
        ranking: true,
        ranking_years: [
            [
                2000
            ]
        ],
        source: 'survey',
        subtype: 'service',
        description: 'Vägnät, tåg- och flygförbindelser.',
        long_description: 'Faktorn beskriver hur företagen upplever vägnätet samt tåg- och flygförbindelser, det vill säga den fysiska infrastrukturen. För företagen är god infrastruktur en avgörande fråga för företagsklimatet eftersom den påverkar såväl leveranser som tillgång till kunder och personal.',
        name: 'Vägnät, tåg- och flyg',
        chartingArray: defaultFactorChartingArray
    },
    {
        slug: 'telecom_and_it_networks',
        api_name: 'Mobilnät och bredband',
        ranking: true,
        ranking_years: [
            [
                2000
            ]
        ],
        source: 'survey',
        subtype: 'service',
        description: 'Mobilnät och bredband.',
        long_description: 'Faktorn beskriver hur företagen upplever mobilnät och bredband i kommunen. I takt med att digitaliseringen ökar blir det allt viktigare för företag att snabbt och enkelt kunna kommunicera med omvärlden. Ett undermåligt fibernät ger en negativ begränsning som i värsta fall kan leda till att viktiga affärer går förlorade.',
        extra_description: 'Ny formulering av frågan från och med 2020, tidigare var det Tele och IT-nät',
        name: 'Mobilnät och bredband',
        chartingArray: dashed2019FactorChartingArray
    },
    {
        slug: 'summary_evaluation',
        api_name: 'Vilket sammanfattande omdöme vill du ge företagsklimatet i kommunen',
        ranking: true,
        ranking_years: [
            [
                2000
            ]
        ],
        source: 'survey',
        subtype: 'service',
        description: 'Sammanfattande omdöme av företagsklimatet i kommunen.',
        long_description: 'Den viktigaste frågan i enkäten, och den tyngst vägande rankingfaktorn, är det sammanfattande omdömet om företagsklimatet i kommunen. Företagarna får utifrån sina egna erfarenheter ge ett samlat betyg på hur de upplever att driva företag i kommunen.',
        name: 'Sammanfattande omdöme',
        chartingArray: defaultFactorChartingArray
    },
    {
        slug: 'attitudes_public',
        api_name: 'Allmänhetens attityder till företagande',
        short_name: 'Allmänhetens attityder',
        ranking: true,
        ranking_years: [
            [
                2000,
                2019
            ]
        ],
        source: 'survey',
        subtype: 'attitude',
        description: 'Allmänhetens attityder till företagande.',
        long_description: 'Faktorn beskriver hur företagen upplever allmänhetens attityder till företagande. Allmänhetens inställning till och kunskaper om företagande har stor betydelse för det lokala företagsklimatet.',
        name: 'Allmänhetens attityder till företagande',
        chartingArray: defaultFactorChartingArray
    },
    {
        slug: 'attitudes_politicians',
        api_name: 'Kommunpolitikernas attityder till företagande',
        short_name: 'Kommunpolitikers attityder',
        ranking: true,
        ranking_years: [
            [
                2000
            ]
        ],
        source: 'survey',
        subtype: 'attitude',
        description: 'Kommunpolitikernas attityder till företagande',
        long_description: 'Det är politikerna som i slutändan har den yttersta makten att fatta de besluten som påverkar förutsättningarna att driva företag i kommunen. Politikernas syn på och samarbete med näringslivet är därför en avgörande faktor för det lokala företagsklimatet.',
        name: 'Kommunpolitikernas attityder till företagande',
        chartingArray: defaultFactorChartingArray
    },
    {
        slug: 'attitudes_civil_servants',
        api_name: 'Kommunala tjänstemäns attityder till företagande',
        short_name: 'Kommunala tjänstemäns attityder',
        ranking: true,
        ranking_years: [
            [
                2000
            ]
        ],
        source: 'survey',
        subtype: 'attitude',
        description: 'Tjänstemäns attityder till företagande.',
        long_description: 'Två av tre företagare har årligen kontakt med sin kommun. Det kan handla om bygglov, miljötillsyn eller en upphandling. Hur tjänstepersoner agerar är av yttersta vikt för hur enkelt det är att starta, driva och utveckla företag i kommunen. Även de tjänstepersoner som inte direkt arbetar med service till företagen behöver inse att deras agerande också påverkar företagsklimatet.',
        name: 'Kommunala tjänstemäns attityder till företagande',
        chartingArray: defaultFactorChartingArray
    },
    {
        slug: 'attitudes_media',
        api_name: 'Medias attityder till företagande',
        short_name: 'Medias attityder',
        ranking: true,
        ranking_years: [
            [
                2000,
                2019
            ]
        ],
        source: 'survey',
        subtype: 'attitude',
        description: 'Medias attityder till företagande.',
        long_description: 'Faktorn visar hur företagarna upplever medias attityder till företagande. Media speglar samhället och har möjlighet att i stor utsträckning påverka bilden av det lokala näringslivet. Att visa en ensidigt negativ bild av företag och företagande skapar ett klimat där färre vill starta och driva företag. Genom att lyfta fram framgångsrika företagare skapas också förebilder för unga människor som går i tankar om att starta företag.',
        extra_description: 'Denna fråga utgår ur enkäten från och med 2020.',
        name: 'Medias attityder till företagande',
        chartingArray: defaultFactorChartingArray
    },
    {
        slug: 'attitudes_schools',
        api_name: 'Skolans kontakt med det lokala näringslivet',
        short_name: 'Skolans kontakt',
        ranking: true,
        ranking_years: [
            [
                2000,
                2019
            ]
        ],
        source: 'survey',
        subtype: 'attitude',
        description: 'Skolans kontakt med det lokala näringslivet',
        long_description: 'Faktorn visar hur företagarna upplever skolans kontakt med det lokala näringslivet. Eftersom dagens unga både är morgondagens arbetstagare och arbetsgivare är det viktigt att skolan förmedlar kunskap om näringslivet och företagande.',
        extra_description: 'Frågans formulering är ändrad 2020. Formuleringen 2002-2019 var "Skolans attityder till företagande".',
        name: 'Skolans kontakt med det lokala näringslivet',
        chartingArray: dashed2019FactorChartingArray
    },
    {
        slug: 'dialog_with_businesses',
        api_name: 'Dialog mellan företag och kommunens beslutsfattare',
        ranking: true,
        ranking_years: [
            [
                2020
            ]
        ],
        source: 'survey',
        name: 'Dialog mellan företag och kommunens beslutsfattare',
        long_description: 'Dialog utgör grunden för kommunens näringslivsarbete, och syftar till att bygga relationer och skapa ömsesidigt förtroende mellan företagen och kommunen. Ömsesidigt förtroende och förståelse är särskilt viktigt i kontakterna mellan företagare och de myndighetsutövande enheterna inom kommunen.',
        extra_description: 'Ny rankinggrundad fråga från 2020.  Frågans formulering ändrades 2020. Formuleringen 2002-2019 var "Dialog mellan företag och kommunledning (politiker och tjänstemän)".',
        chartingArray: dashed2019FactorChartingArray
    },
    {
        slug: 'initiatives_from_businesses',
        api_name: 'Företagens engagemang för att förbättra företagsklimatet',
        ranking: false,
        source: 'survey',
        name: 'Företagens engagemang',
        long_description: 'Företagens engagemang för att förbättra företagsklimatet',
        extra_description: 'Frågans formulering ändrades 2020. Formuleringen 2002-2019 var "Företagens egna initiativ för att förbättra företagsklimatet"',
        chartingArray: dashed2019FactorChartingArray,
    },
    {
        slug: 'information_to_businesses',
        api_name: 'Kommunens information till företagen',
        ranking: true,
        ranking_years: [
            [
                2020
            ]
        ],
        source: 'survey',
        name: 'Kommunens information till företagen',
        long_description: 'Faktorn mäter hur väl kommunen lyckas med information till företagen. Det händer mycket i en kommun som är relevant för företagen att veta om. Det kan vara information som berör företagen direkt men också som är av indirekt karaktär.',
        extra_description: 'Ny rankinggrundad fråga från 2020.',
        chartingArray: defaultFactorChartingArray
    },
    {
        slug: 'municipal_contracting',
        api_name: 'Kommunens upphandling',
        ranking: true,
        ranking_years: [
            [
                2020
            ]
        ],
        source: 'survey',
        name: 'Kommunens upphandling',
        long_description: 'Faktorn mäter företagens upplevelse av den kommunala upphandlingen. Den kommun som lyckas med sitt upphandlingsarbete skapar stor nytta för både medborgarna, kommunen, det lokala näringslivet och i slutänden hela samhället genom att använda skattemedel på ett effektivt och ändamålsenligt sätt.',
        extra_description: 'Ny rankinggrundad fråga från 2020.',
        chartingArray: defaultFactorChartingArray
    },
    {
        slug: 'summary_evaluation_sweden',
        api_name: 'Vilket sammanfattande omdöme vill du ge företagsklimatet i Sverige',
        ranking: false,
        source: 'survey',
        name: 'Företagsklimatet i Sverige',
        long_description: 'Sammanfattande omdöme om företagsklimatet i Sverige',
        chartingArray: defaultFactorChartingArray
    },
    {
        slug: 'type_of_business',
        api_name: 'Inom vilken bransch är ditt företag huvudsakligen verksamt?',
        ranking: false,
        source: 'survey',
        name: 'Inom vilken bransch är ditt företag huvudsakligen verksamt?',
        long_description: 'Inom vilken bransch är ditt företag huvudsakligen verksamt?',
        chartingArray: [
            {
                chart: {
                    defaultChartDataEndpointName: 'SurveySimpleBarchart',
                    extraLabelWidth: 130,
                    truncate: true
                },
                factorComparison: {
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'muni_contact_latest_year',
        api_name: 'Har du under senaste året haft kontakt med kommunen?',
        ranking: false,
        source: 'survey',
        name: 'Har du under senaste året haft kontakt med kommunen?',
        long_description: 'Har du under senaste året haft kontakt med kommunen?',
        chartingArray: [
            {
                chart: {
                    defaultChartDataEndpointName: 'SurveySimpleBarchart',
                    extraLabelWidth: 130,
                    truncate: true
                },
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'muni_contact_latest_year_about',
        api_name: 'Gällde kontakten något av följande',
        ranking: false,
        source: 'survey',
        name: 'Om kontakt med kommunen förekom: Gällde kontakten något av följande?',
        long_description: 'Om kontakt med kommunen förekom: Gällde kontakten något av följande? (Flera svar möjliga)',
        chartingArray: [
            {
                chart: {
                    defaultChartDataEndpointName: 'SurveySimpleBarchart',
                    extraLabelWidth: 130,
                    truncate: true,
                    sorting: {
                        sortDir: 'desc',
                        sortOrder: 'value'
                    }
                },
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'muni_contact_hesitate',
        api_name: 'Har du under de senaste 12 månaderna, utifrån risken att det kan komma att påverka ditt företag negativt, känt tvekan att lämna klagomål till kommunen?',
        ranking: false,
        source: 'survey',
        name: 'Tvekan att lämna klagomål till kommunen?',
        long_description: 'Har du under de senaste 12 månaderna, utifrån risken att det kan komma att påverka ditt företag negativt, känt tvekan att lämna klagomål till kommunen?',
        extra_description: 'Denna fråga ställdes som extrafråga 2024, 2022, 2020 samt 2016. Formuleringen före 2024 var ”Har du någon gång känt tvekan att lämna klagomål till kommunen med risken att detta kommer påverka ditt företag negativt?”',
        chartingArray: [
            {
                chart: {
                    defaultChartDataEndpointName: 'SurveySimpleBarchart',
                    year: 2024,
                },
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
        // chartingArray: [
        //   {
        //     chart: {
        //       xscales: [
        //         ''
        //       ],
        //       defaultChartDataEndpointName: 'MunicipalityFactorValues'
        //     },
        //     factorComparison: {
        //       withoutGradingScale: false,
        //       gradingScaleType: 'named',
        //       applicableToPoliticians: false
        //     }
        //   }
        // ]
    },
    {
        slug: 'company_education_contact',
        api_name: 'Vill ni, på ditt företag, ha kontakt med skolan i din kommun? I så fall på vilket sätt?',
        ranking: false,
        source: 'survey',
        name: 'Vill ni, på ditt företag, ha kontakt med skolan i din kommun?',
        long_description: 'Vill ni, på ditt företag, ha kontakt med skolan i din kommun? I så fall på vilket sätt?',
        extra_description: 'Denna fråga ställdes som extrafråga 2023.',
        chartingArray: [
            {
                chart: {
                    defaultChartDataEndpointName: 'SurveySimpleBarchart',
                    year: 2023,
                    extraLabelWidth: 70,
                },
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'company_education_available',
        api_name: 'Finns det utbildningar i ditt närområde som matchar ditt företags kompetensbehov?',
        ranking: false,
        source: 'survey',
        name: 'Finns det utbildningar som matchar ditt företags kompetensbehov?',
        long_description: 'Finns det utbildningar i ditt närområde som matchar ditt företags kompetensbehov?',
        extra_description: 'Denna fråga ställdes som extrafråga 2023.',
        chartingArray: [
            {
                chart: {
                    defaultChartDataEndpointName: 'SurveySimpleBarchart',
                    year: 2023,
                },
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'muni_measures_view',
        api_name: 'I vilken utsträckning vidtar kommunen åtgärder utifrån de synpunkter som företagare i kommunen har?',
        ranking: false,
        source: 'survey',
        name: 'Åtgärder utifrån företagares synpunkter?',
        long_description: 'I vilken utsträckning vidtar kommunen åtgärder utifrån de synpunkter som företagare i kommunen har?\'',
        extra_description: 'Denna fråga ställdes som extrafråga 2022.',
        chartingArray: [
            {
                chart: {
                    defaultChartDataEndpointName: 'SurveySimpleBarchart',
                    year: 2022
                },
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'advice_on_permission',
        api_name: 'Företaget får de råd och den vägledning som vi behöver vid kommunens tillståndsgivning, tillsyn eller kontroll.',
        ranking: false,
        source: 'survey',
        description: 'I vilken utsträckning instämmer du i följande påstående: Företaget får de råd och den vägledning som vi behöver vid kommunens tillståndsgivning, tillsyn eller kontroll.',
        name: 'Vägledning vid tillståndsgivning, tillsyn eller kontroll',
        long_description: 'Företaget får de råd och den vägledning som vi behöver vid kommunens tillståndsgivning, tillsyn eller kontroll.',
        extra_description: 'Frågan ställs endast till de företag som svarat att de haft kontakt med kommunen angående tillståndsgivning, tillsyn eller kontroll.',
        chartingArray: [
            {
                chart: {
                    xscales: [
                        ''
                    ],
                    defaultChartDataEndpointName: 'MunicipalityFactorValues'
                },
                factorComparison: {
                    withoutGradingScale: false,
                    gradingScaleType: 'named',
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'crime_a_problem',
        api_name: 'Upplever du att brottslighet är ett problem för företagen i din kommun?',
        ranking: false,
        source: 'survey',
        name: 'Är brottslighet ett problem för företagen?',
        long_description: 'Politiker: Upplever du att brottslighet är ett problem för företagen i din kommun? Företagare: Upplever du att brottslighet är ett problem för ditt företag?',
        extra_description: 'Denna fråga ställdes som extrafråga 2017.',
        chartingArray: [
            {
                chart: {
                    defaultChartDataEndpointName: 'SurveySimpleBarchartCombined',
                    extraHeight: true,
                    year: 2017
                },
                factorComparison: {
                    withoutGradingScale: true,
                    includeSwedenByDefault: false
                }
            }
        ]
    },
    {
        slug: 'crime_change_2years',
        api_name: 'Hur upplever du att brottsligheten mot företag har förändrats i din kommun under de senaste två åren?',
        ranking: false,
        source: 'survey',
        name: 'Hur har brottsligheten förändrats de senaste två åren?',
        long_description: 'Politiker: Hur upplever du att brottsligheten mot företag har förändrats i din kommun under de senaste två åren? Företagare: Hur upplever du att brottsligheten mot ditt företag har förändrats under de senaste två åren?',
        extra_description: 'Denna fråga ställdes som extrafråga 2017.',
        chartingArray: [
            {
                chart: {
                    defaultChartDataEndpointName: 'SurveySimpleBarchartCombined',
                    extraHeight: true,
                    year: 2017,
                    xscales: [
                        'Brottsligheten har ökat',
                        'Brottsligheten är oförändrad',
                        'Brottsligheten har minskat',
                        'Vet ej'
                    ]
                },
                factorComparison: {
                    withoutGradingScale: true,
                    includeSwedenByDefault: false
                }
            }
        ]
    },
    {
        slug: 'crime_risk_kind',
        api_name: 'För vilken/vilka av följande typer av brott bedömer du att risken att ditt företag ska drabbas är hög?',
        ranking: false,
        source: 'survey',
        name: 'Vilka brottstyper bedömer du att det är hög risk att drabbas av?',
        long_description: 'För vilken/vilka av följande typer av brott bedömer du att risken att ditt företag ska drabbas är hög?',
        extra_description: 'Denna fråga ställdes som extrafråga 2017.',
        chartingArray: [
            {
                chart: {
                    defaultChartDataEndpointName: 'SurveySimpleBarchart',
                    year: 2017,
                    sorting: {
                        sortDir: 'desc',
                        sortOrder: 'value'
                    }
                },
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'crime_a_problem_own_business',
        name: 'Påverkan av brottslighet/otrygghet',
        api_name: 'I vilken utsträckning påverkas ditt företag negativt av brottslighet/otrygghet i din kommun?',
        ranking: true,
        ranking_years: [
            [
                2020
            ]
        ],
        source: 'survey',
        description: 'I vilken utsträckning påverkas ditt företag negativt av brottslighet/otrygghet i din kommun?',
        long_description: 'Brottslighet och otrygghet hämmar företagens tillväxt- och utvecklingsmöjligheter. Faktorn beskriver i vilken utsträckning som företag påverkas negativt av brottslighet/otrygghet i kommunen.',
        extra_description: 'Ny rankinggrundad fråga från 2020.',
        chartingArray: defaultFactorChartingArray
            .map(function (charting) {
            var charting_ = __assign(__assign({}, charting), { factorComparison: {
                    gradingScaleType: 'named-rev',
                    applicableToPoliticians: false
                } });
            charting_.chart = __assign(__assign({}, charting_.chart), { titleOverride: "Brottslighet och otrygghet" });
            return charting_;
        })
    },
    {
        slug: 'company_relocate',
        api_name: 'Har du någon gång övervägt att flytta ditt företag till en kommun med ett bättre företagsklimat?',
        ranking: false,
        source: 'survey',
        //description: 'Har du övervägt att flytta företaget?',
        long_description: 'Har du någon gång övervägt att flytta ditt företag till en kommun med ett bättre företagsklimat?',
        extra_description: 'Denna fråga ställdes som extrafråga 2024, 2020 samt 2017.',
        name: 'Har du övervägt att flytta företaget?',
        chartingArray: [
            {
                chart: {
                    defaultChartDataEndpointName: 'SurveySimpleBarchart',
                    year: 2024
                },
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'business_climate_change_5_years',
        api_name: 'Upplever du att företagsklimatet blivit bättre, oförändrat eller sämre i din kommun senaste 5 åren?',
        ranking: false,
        source: 'survey',
        name: 'Hur har företagsklimatet förändrats de senaste 5 åren?',
        long_description: 'Upplever du att företagsklimatet blivit bättre, oförändrat eller sämre i din kommun senaste 5 åren?',
        extra_description: 'Denna fråga ställdes som extrafråga 2021, 2018 samt 2015.',
        chartingArray: [
            {
                chart: {
                    defaultChartDataEndpointName: 'SurveySimpleBarchartCombined',
                    extraHeight: true,
                    year: 2021
                },
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: true,
                    includeSwedenByDefault: false
                }
            }
        ]
    },
    {
        slug: 'municipality_runs_competing_businesses',
        api_name: 'Bedriver din kommun eller kommunala bolag verksamhet som ditt företag skulle vilja utföra?',
        ranking: false,
        source: 'survey',
        name: 'Bedriver din kommun verksamhet som ditt företag skulle vilja utföra?',
        long_description: 'Bedriver din kommun eller kommunala bolag verksamhet som ditt företag skulle vilja utföra?',
        extra_description: 'Denna fråga ställdes som extrafråga 2018 samt 2011.',
        chartingArray: [
            {
                chart: {
                    defaultChartDataEndpointName: 'SurveySimpleBarchart',
                    year: 2018
                },
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'survey_contact_understanding',
        api_name: 'Jag upplever att kommunens handläggare hade förståelse för min verksamhet.',
        ranking: false,
        source: 'survey',
        description: 'I vilken utsträckning instämmer du i följande påstående: Jag upplever att kommunens handläggare hade förståelse för min verksamhet.',
        name: 'Förståelse för verksamheten',
        long_description: 'I vilken utsträckning instämmer du i följande påstående: Jag upplever att kommunens handläggare hade förståelse för min verksamhet.',
        extra_description: 'Frågan ställs endast till de företag som svarat att de haft kontakt med kommunen angående tillståndsgivning, tillsyn eller kontroll.',
        chartingArray: [
            {
                chart: {
                    xscales: [
                        ''
                    ],
                    defaultChartDataEndpointName: 'MunicipalityFactorValues'
                },
                factorComparison: {
                    withoutGradingScale: false,
                    gradingScaleType: 'named',
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'survey_contact_clearfee',
        api_name: 'Det framstår som tydligt för mig hur kommunen beräknat sin avgift.',
        ranking: false,
        source: 'survey',
        description: 'I vilken utsträckning instämmer du i följande påstående: Det framstår som tydligt för mig hur kommunen beräknat sin avgift.',
        name: 'Tydligt beräknad avgift',
        long_description: 'I vilken utsträckning instämmer du i följande påstående: Det framstår som tydligt för mig hur kommunen beräknat sin avgift.',
        extra_description: 'Denna fråga ställdes som extrafråga 2019.',
        chartingArray: [
            {
                chart: {
                    extraLabelWidth: 130,
                    xscales: xScalesGradingGoodnessLevelOrderLowHigh,
                    defaultChartDataEndpointName: 'SurveySimpleBarchart',
                    year: 2019
                },
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'survey_contact_handling',
        api_name: 'Kommunens handläggningstid framstår som rimlig.',
        ranking: false,
        source: 'survey',
        description: 'I vilken utsträckning instämmer du i följande påstående: Kommunens handläggningstid framstår som rimlig.',
        name: 'Rimlig handläggningstid',
        long_description: 'I vilken utsträckning instämmer du i följande påstående: Kommunens handläggningstid framstår som rimlig.',
        extra_description: 'Frågan ställs endast till de företag som svarat att de haft kontakt med kommunen angående tillståndsgivning, tillsyn eller kontroll.',
        chartingArray: [
            {
                chart: {
                    xscales: [
                        ''
                    ],
                    defaultChartDataEndpointName: 'MunicipalityFactorValues'
                },
                factorComparison: {
                    withoutGradingScale: false,
                    gradingScaleType: 'named',
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'survey_contact_reachable',
        api_name: 'Det är lätt för mig att komma i kontakt med kommunens handläggare.',
        ranking: false,
        source: 'survey',
        description: 'I vilken utsträckning instämmer du i följande påstående: Det är lätt för mig att komma i kontakt med kommunens handläggare.',
        name: 'Lätt att komma i kontakt med handläggare',
        long_description: 'I vilken utsträckning instämmer du i följande påstående: Det är lätt för mig att komma i kontakt med kommunens handläggare.',
        extra_description: 'Frågan ställs endast till de företag som svarat att de haft kontakt med kommunen angående tillståndsgivning, tillsyn eller kontroll.',
        chartingArray: [
            {
                chart: {
                    xscales: [
                        ''
                    ],
                    defaultChartDataEndpointName: 'MunicipalityFactorValues'
                },
                factorComparison: {
                    withoutGradingScale: false,
                    gradingScaleType: 'named',
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'survey_contact_digitized',
        api_name: 'Jag skulle önska en mer digitaliserad hantering av kommunens ärenden.',
        ranking: false,
        source: 'survey',
        description: 'I vilken utsträckning instämmer du i följande påstående: Jag skulle önska en mer digitaliserad hantering av kommunens ärenden.',
        name: 'Mer digitaliserad hantering av ärenden',
        long_description: 'I vilken utsträckning instämmer du i följande påstående: Jag skulle önska en mer digitaliserad hantering av kommunens ärenden.',
        extra_description: 'Denna fråga ställdes som extrafråga 2019.',
        chartingArray: [
            {
                chart: {
                    extraLabelWidth: 130,
                    xscales: xScalesGradingGoodnessLevelOrderLowHigh,
                    defaultChartDataEndpointName: 'SurveySimpleBarchart',
                    year: 2019
                },
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'how_important_for_company_relevant_highshool_programs',
        api_name: 'Relevanta gymnasieprogram',
        ranking: false,
        source: 'survey',
        subtype: 'importance',
        name: 'Relevant gymnasieprogram',
        long_description: 'Hur viktiga är följande erfarenheter/utbildningar vid rekryteringar till ditt företag? Relevant gymnasieprogram',
        chartingArray: [
            {
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'how_important_for_company_company_trainee_positions',
        api_name: 'Företagsförlagd praktik',
        ranking: false,
        source: 'survey',
        subtype: 'importance',
        name: 'Företagsförlagd praktik',
        long_description: 'Hur viktiga är följande erfarenheter/utbildningar vid rekryteringar till ditt företag? Företagsförlagd praktik',
        chartingArray: [
            {
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'how_important_for_company_relevant_skilled_workers_programs',
        api_name: 'Relevant eftergymnasial yrkesutbildning',
        ranking: false,
        source: 'survey',
        subtype: 'importance',
        name: 'Relevant eftergymnasial yrkesutbildning',
        long_description: 'Hur viktiga är följande erfarenheter/utbildningar vid rekryteringar till ditt företag? Relevant eftergymnasial yrkesutbildning',
        chartingArray: [
            {
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'how_important_for_company_relevant_higher_education',
        api_name: 'Relevant högskola/universitetsutbildning',
        ranking: false,
        source: 'survey',
        subtype: 'importance',
        name: 'Relevant högskola/universitetsutbildning',
        long_description: 'Hur viktiga är följande erfarenheter/utbildningar vid rekryteringar till ditt företag? Relevant högskola/universitetsutbildning',
        chartingArray: [
            {
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'how_important_for_company_targeted_adult_education',
        api_name: 'Branschinriktad vuxenutbildning',
        ranking: false,
        source: 'survey',
        subtype: 'importance',
        name: 'Branschinriktad vuxenutbildning',
        long_description: 'Hur viktiga är följande erfarenheter/utbildningar vid rekryteringar till ditt företag? Branschinriktat vuxenutbildning',
        chartingArray: [
            {
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'how_important_for_company_summer_jobs',
        api_name: 'Sommar-/extrajobb',
        ranking: false,
        source: 'survey',
        subtype: 'importance',
        name: 'Sommar-/extrajobb',
        long_description: 'Hur viktiga är följande erfarenheter/utbildningar vid rekryteringar till ditt företag? Sommar-/extrajobb',
        chartingArray: [
            {
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'how_important_for_company_work_experience',
        api_name: 'Några års arbetslivserfarenhet',
        ranking: false,
        source: 'survey',
        subtype: 'importance',
        name: 'Några års arbetslivserfarenhet',
        long_description: 'Hur viktiga är följande erfarenheter/utbildningar vid rekryteringar till ditt företag? Några års arbetslivserfarenhet',
        chartingArray: [
            {
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'how_important_for_muni_housing',
        api_name: 'Bostäder',
        ranking: false,
        source: 'survey',
        subtype: 'importance',
        name: 'Bostäder',
        long_description: 'Hur viktiga är följande frågor inför framtiden för att stärka näringslivet och därmed kommunen? Bostäder',
        chartingArray: [
            {
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'how_important_for_muni_entrepreneurial_climate',
        api_name: 'Bättre företagsklimat',
        ranking: false,
        source: 'survey',
        subtype: 'importance',
        name: 'Bättre företagsklimat',
        long_description: 'Hur viktiga är följande frågor inför framtiden för att stärka näringslivet och därmed kommunen? Bättre företagsklimat',
        chartingArray: [
            {
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'how_important_for_muni_more_companies',
        api_name: 'Fler företag',
        ranking: false,
        source: 'survey',
        subtype: 'importance',
        name: 'Fler företag',
        long_description: 'Hur viktiga är följande frågor inför framtiden för att stärka näringslivet och därmed kommunen? Fler företag',
        chartingArray: [
            {
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'how_important_for_muni_pop_moving_in',
        api_name: 'Få fler att flytta till kommunen',
        ranking: false,
        source: 'survey',
        subtype: 'importance',
        name: 'Få fler att flytta till kommunen',
        long_description: 'Hur viktiga är följande frågor inför framtiden för att stärka näringslivet och därmed kommunen? Få fler att flytta till kommunen',
        chartingArray: [
            {
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'how_important_for_muni_youth_staying',
        api_name: 'Få unga att bo kvar i kommunen',
        ranking: false,
        source: 'survey',
        subtype: 'importance',
        name: 'Få unga att bo kvar i kommunen',
        long_description: 'Hur viktiga är följande frågor inför framtiden för att stärka näringslivet och därmed kommunen? Få unga att bo kvar i kommunen',
        chartingArray: [
            {
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'how_important_for_muni_quality_education',
        api_name: 'God kvalitet i skola/utbildning',
        ranking: false,
        source: 'survey',
        subtype: 'importance',
        name: 'God kvalitet i skola/utbildning',
        long_description: 'Hur viktiga är följande frågor inför framtiden för att stärka näringslivet och därmed kommunen? God kvalitet i skola/utbildning',
        chartingArray: [
            {
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'how_important_for_muni_competent_workers',
        api_name: 'Kompetensförsörjning',
        ranking: false,
        source: 'survey',
        subtype: 'importance',
        name: 'Kompetensförsörjning',
        long_description: 'Hur viktiga är följande frågor inför framtiden för att stärka näringslivet och därmed kommunen? Kompetensförsörjning',
        chartingArray: [
            {
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'how_important_for_muni_diverse_entrepreneurial_climate',
        api_name: 'Mer diversifierat näringsliv',
        ranking: false,
        source: 'survey',
        subtype: 'importance',
        name: 'Mer diversifierat näringsliv',
        long_description: 'Hur viktiga är följande frågor inför framtiden för att stärka näringslivet och därmed kommunen? Mer diversifierat näringsliv',
        chartingArray: [
            {
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'higher_education',
        api_name: 'Högre utbildningsnivå',
        ranking: false,
        source: 'statistics',
        name: 'Högre utbildningsnivå',
        long_description: 'Statistiken hämtas från SCB:s Utbildningsregister. Diagrammet visar andelen med eftergymnasial utbildning i åldern 16-74 år av totalbefolkningen.'
    },
    {
        slug: 'new_businesses',
        api_name: 'Nyföretagande',
        ranking: false,
        source: 'statistics',
        name: 'Nyföretagande',
        long_description: 'Statistiken hämtas från SCB:s Företagsdatabas. Nyföretagande visar antal nya företag per 1 000 invånare. 2010 skedde en stor ökning i nyföretagandet jämfört med tidigare år. Anledningen till det är främst ändrade regler för att sätta aktivitetsstatus för företag.'
    },
    {
        slug: 'foreign_companies',
        api_name: 'Andel utländska bolag',
        ranking: false,
        source: 'statistics',
        name: 'Andel utländska bolag',
        long_description: 'Visar andelen utlandsägda bolag av det totala antalet bolag i kommunen. Statistiken hämtas från SCB:s företagsdatabas.'
    },
    {
        slug: 'foreign_guest_nights',
        api_name: 'Utländska gästnätter per invånare',
        ranking: false,
        source: 'statistics',
        name: 'Utländska gästnätter per invånare',
        long_description: 'Utländska gästnätter per invånare'
    },
    {
        slug: 'employment_in_foreign_companies',
        api_name: 'Andel anställda i utlandsägda bolag',
        ranking: false,
        source: 'statistics',
        name: 'Andel anställda i utlandsägda bolag',
        long_description: 'Visar andelen anställda i utlandsägda bolag av det totala antalet anställda i kommunen. Statistiken hämtas från SCB:s företagsdatabas.'
    },
    {
        slug: 'entrepreneurship',
        api_name: 'Företagsamhet',
        ranking: true,
        ranking_years: [
            [
                2020
            ]
        ],
        source: 'statistics',
        name: 'Företagsamhet',
        description: 'Faktorn visar andelen företagsamma av den arbetsföra befolkningen (16-74 år) samt bland de unga (16-34 år)',
        long_description: 'De individer som är godkända för F-skatt, är delägare i ett aktivt handelsbolag eller är vd eller styrelsemedlem i ett aktivt aktiebolag räknas som företagsamma. Faktorn visar andelen företagsamma av den arbetsföra befolkningen (16-74 år).',
        extra_description: 'Ny rankinggrundad statistik från 2020. ',
        chartingArray: [
            {
                chart: {
                    sourceExclude: 'kreicberg'
                },
                factorComparison: {
                    includeSwedenByDefault: false
                }
            }
        ]
    },
    {
        slug: 'population',
        api_name: 'Folkmängd',
        ranking: false,
        source: 'statistics',
        name: 'Folkmängd',
        long_description: 'Antalet invånare 31 december varje år. Källa: SCB',
        chartingArray: [
            {
                chart: {
                    isIntegerData: true
                },
                factorComparison: {
                    includeSwedenByDefault: false
                }
            }
        ]
    },
    {
        slug: 'municipal_economy',
        api_name: 'Kommunal ekonomi',
        ranking: false,
        source: 'statistics',
        name: 'Kommunal ekonomi',
        long_description: 'Kommunal ekonomi, totala kostnader per invånare, är verksamhetens kostnader för kommunkoncernen (inklusive kommunägda bolag) per invånare. Uppgifterna hämtas från Räkenskapssammandraget som årligen sammanställs av SCB.',
        chartingArray: [
            {
                chart: {
                    isIntegerData: true
                },
                factorComparison: {
                    lacksCountyData: true
                }
            }
        ]
    },
    {
        slug: 'global_trade_per_capita',
        api_name: 'Global handel per invånare',
        ranking: false,
        source: 'statistics',
        name: 'Global handel per invånare',
        long_description: 'Statistiken visar export och import i kronor per invånare. Statistiken hämtas från SCB. För årtalen 2009 och 2010 ingår även tjänstehandel vilket gör att jämförelser med åren innan och efter blir missvisande.',
        chartingArray: [
            {
                chart: {
                    isIntegerData: true
                }
            }
        ]
    },
    {
        slug: 'net_population_change',
        api_name: 'In- och utflyttning',
        ranking: false,
        source: 'statistics',
        name: 'In- och utflyttning',
        long_description: 'Statistiken visar nettoinflyttningen till kommunen i absoluta tal för åldersgrupperna 15-24 år samt 25-34 år. Källa: SCB.',
        chartingArray: [
            {
                chart: {
                    xscales: [
                        'Ålder 15-24',
                        'Ålder 25-34'
                    ]
                },
                factorComparison: {
                    lacksCountyData: true,
                    excludeSweden: true
                }
            }
        ]
    },
    {
        slug: 'retirees',
        api_name: 'Pensionärer',
        ranking: false,
        source: 'statistics',
        name: 'Pensionärer',
        long_description: 'Diagrammet visar antalet invånare över 65 respektive 80 år som andel av befolkningen mellan 19-64 år.',
        chartingArray: [
            {
                chart: {
                    xscales: [
                        'Kvot 65+/19-64',
                        'Kvot 80+/19-64'
                    ]
                },
                factorComparison: {
                    singleDimensionComparable: false
                }
            }
        ]
    },
    {
        slug: 'net_costs',
        api_name: 'Nettokostnader',
        ranking: false,
        source: 'statistics',
        name: 'Nettokostnader',
        long_description: 'Statistiken visar kommunens nettokostnader per invånare för kommunens olika verksamhetsområden. Källa: SCB.',
        chartingArray: [
            {
                chart: {
                    year: 2023,
                    isIntegerData: true,
                    defaultChartDataEndpointName: 'InDepthSurveyQuestions',
                    extraLabelWidth: 70,
                    truncate: true,
                    unit: 'kr/invånare',
                    sorting: {
                        sortOrder: 'value',
                        sortDir: 'desc'
                    }
                },
                factorComparison: {
                    singleDimensionComparable: false,
                    lacksCountyData: true
                }
            }
        ]
    },
    {
        slug: 'largest_employers',
        api_name: 'Största arbetsgivare',
        ranking: false,
        source: 'statistics',
        name: 'Största arbetsgivare',
        long_description: 'Största arbetsgivare i kommunen. Andelen beräknas på dagbefolkningen i kommunen.',
        chartingArray: [
            {
                chart: {
                    year: 2023,
                    isIntegerData: false,
                    defaultChartDataEndpointName: 'InDepthSurveyQuestions',
                    extraLabelWidth: 100,
                    truncate: true,
                    unit: 'Procentuell andel av totalt antal anställda i kommunen',
                    yscales: [
                        'Procentuell andel av totalt antal anställda i kommunen'
                    ],
                    sorting: {
                        sortOrder: 'value',
                        sortDir: 'desc'
                    }
                },
                factorComparison: {
                    singleDimensionComparable: false,
                    disabled: true,
                    excludeSweden: true
                }
            }
        ]
    },
    {
        slug: 'largest_private_employers',
        api_name: 'Största privata arbetsgivare',
        ranking: false,
        source: 'statistics',
        name: 'Största privata arbetsgivare',
        long_description: 'Största privata arbetsgivare i kommunen. Andelen beräknas på dagbefolkningen i kommunen.',
        chartingArray: [
            {
                chart: {
                    year: 2023,
                    isIntegerData: false,
                    defaultChartDataEndpointName: 'InDepthSurveyQuestions',
                    extraLabelWidth: 100,
                    truncate: true,
                    unit: 'Procentuell andel av totalt antal anställda i kommunen',
                    yscales: [
                        'Procentuell andel av totalt antal anställda i kommunen'
                    ],
                    sorting: {
                        sortOrder: 'value',
                        sortDir: 'desc'
                    }
                },
                factorComparison: {
                    singleDimensionComparable: false,
                    disabled: true,
                    excludeSweden: true
                }
            }
        ]
    },
    {
        slug: 'unemployment',
        api_name: 'Arbetslöshet',
        ranking: false,
        source: 'statistics',
        name: 'Arbetslöshet',
        long_description: 'Tabellen visar öppet arbetslösa och sökande i program med aktivitetsstöd i procent av den registerbaserade arbetskraften i åldersgrupperna 16-64 år och 18-24 år (ungdomsarbetslösheten). Källa: Arbetsförmedlingen.'
    },
    {
        slug: 'jobs_per_business',
        api_name: 'Arbetstillfällen efter näringsgren',
        ranking: false,
        source: 'statistics',
        name: 'Arbetstillfällen efter näringsgren',
        long_description: 'Arbetstillfällen efter näringsgren',
        chartingArray: [
            {
                chart: {
                    year: 2021,
                    defaultChartDataEndpointName: 'InDepthSurveyQuestions',
                    extraLabelWidth: 100,
                    extraHeight: true,
                    truncate: true,
                    unit: '%',
                    sorting: {
                        sortOrder: 'value',
                        sortDir: 'desc'
                    }
                },
                factorComparison: {
                    singleDimensionComparable: false,
                    lacksCountyData: true
                }
            }
        ]
    },
    {
        slug: 'most_prioritized_actions',
        name: 'Prioriterade åtgärder',
        api_name: 'Vilket eller vilka av följande områden tycker du kommunen ska prioritera för att förbättra företagsklimatet i kommunen?',
        ranking: false,
        source: 'survey',
        long_description: 'Vilket eller vilka av följande områden tycker du kommunen ska prioritera för att förbättra företagsklimatet i kommunen?',
        chartingArray: [
            {
                chart: {
                    defaultChartDataEndpointName: 'SurveySimpleBarchart',
                    extraHeight: true,
                    extraLabelWidth: 130,
                    truncate: true,
                    year: SURVEY_YEAR_LATEST,
                    sorting: {
                        sortDir: 'desc',
                        sortOrder: 'value'
                    }
                },
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false,
                    includeSwedenByDefault: false
                }
            }
        ]
    },
    {
        slug: 'knowledge_of_muni_work',
        name: 'Kunskap om kommunens arbete',
        api_name: 'Känner du att du har kunskap om hur kommunen arbetar för att förbättra företagsklimatet?',
        ranking: false,
        source: 'survey',
        long_description: 'Företagare: "Känner du att du har kunskap om hur kommunen arbetar för att förbättra företagsklimatet?". Politiker: "Involverar kommunen företagen i kommunens arbete för att förbättra företagsklimatet?"',
        extra_description: 'Denna fråga ställdes som extrafråga 2021, 2018 samt 2015. Dataserien finns att ladda ner under menyfliken "ladda ner".',
        chartingArray: [
            {
                chart: {
                    defaultChartDataEndpointName: 'SurveySimpleBarchartCombined',
                    extraHeight: true,
                    year: 2021
                },
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: true,
                    includeSwedenByDefault: false
                }
            }
        ]
    },
    {
        slug: 'how_many_employees_municipality',
        name: 'Hur många anställda har ditt företag i den här kommunen?',
        api_name: 'Hur många anställda har ditt företag i den här kommunen?',
        ranking: false,
        source: 'survey',
        long_description: 'Hur många anställda har ditt företag i den här kommunen?',
        chartingArray: [
            {
                chart: {
                    defaultChartDataEndpointName: 'SurveySimpleBarchart',
                    xscales: [
                        'Inga anställda',
                        '1-5 anställda',
                        '6-25 anställda',
                        '26-50 anställda',
                        '51-100 anställda',
                        'Fler än 100 anställda'
                    ]
                },
                factorComparison: {
                    withoutGradingScale: true,
                    applicableToPoliticians: false
                }
            }
        ]
    },
    {
        slug: 'ranking',
        api_name: 'Ranking',
        ranking: false,
        source: 'ranking',
        description: 'Rankingutveckling',
        long_description: 'Varje år presenterar Svenskt Näringsliv en ranking av företagsklimatet i Sveriges 290 kommuner. Syftet är att visa var i landet det är bäst att starta och driva företag.',
        name: 'Ranking',
        chartingArray: [
            {
                chart: {
                    isIntegerData: true,
                    isSpecialInvertedRankingChart: true,
                    unitExclude: 'Rank',
                    years: [2015, 2024]
                },
                factorComparison: {
                    singleDimensionComparable: true,
                    isBiggerBetter: false,
                    withoutGradingScale: true,
                    lacksCountyData: true,
                    excludeSweden: true
                }
            }
        ]
    }
];
exports.gradingScaleTypeDescription = {
    'level': {
        preamble: 'Betyg sätts på en skala mellan 1 och 6 där ',
        scaleDescription: '1 betyder "i stor utsträckning" och 6 betyder "inte alls".'
    },
    'named': {
        preamble: 'Betyg sätts på en skala mellan 6 och 1 där ',
        scaleDescription: '6=I mycket hög utsträckning, I ganska hög.., I varken hög eller låg.., I ganska låg.., 2=I mycket låg utsträckning och 1=Inte alls.'
    },
    'named-rev': {
        preamble: 'Betyg sätts på en skala mellan 1 och 6 där ',
        scaleDescription: '1=I mycket hög utsträckning, I ganska hög.., I varken eller, I ganska låg.., I mycket låg utsträckning och 6=Inte alls.'
    },
    'goodness': {
        preamble: 'Betyg sätts på en skala mellan ',
        scaleDescription: '1 = dåligt och 6 = utmärkt. Positiv andel är betyg 4 (bra) eller högre.'
    }
};
exports.ChartDataTransformerRules = [
    { ruleName: 'Identity', transformer: chartData_1.identityTransformer },
    { ruleName: 'EntrepreneurRespondents', transformer: chartData_1.seriesNamesFilterer(['Företagare']) },
    { ruleName: 'PoliticianRespondents', transformer: chartData_1.seriesNamesFilterer(['Politician']) },
    { ruleName: 'BothRespondents', transformer: chartData_1.seriesNamesFilterer(['Företagare', 'Politiker']) }
];
// re-type it to 'Factors[]' and export
exports.Factors = _Factors;
